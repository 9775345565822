/* Parent container to center grid-container */
/* .parent-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 1129px;
  gap: 20px;

  padding: 0;
  border: none;

}

.grid-item {

  padding: 10px;
}

.grid-item img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 10px;
} */













.image-container {
  position: relative;
  display: inline-block;
  width: 100%;
  background-color: #010006;
}

.banner {
  width: 100%;
}

.centered-text {
  color: white;
  font-size: 50px !important;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
}




.flip-card {
  background-color: transparent;
  width: 300px;
  height: 300px;
  perspective: 1000px;
  /* This gives the 3D effect */
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  /* Safari */
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  background-color: #DD223E;
  color: white;
  transform: rotateY(180deg);
  display: flex;
  align-items: left;
  justify-content: left;
  flex-direction: column;
  padding: 1rem;
}

p {
  text-align: left;
}

h6 {
  text-align: left;
  font-size: 16px
}

h3 {
  text-align: left;
  font-size: 20px
}

.team-layout {
  padding: 2rem 4rem;
}

.text-overlay h1{
  font-weight: 900;
  font-size: 64px;
  font-family: 'Inter';
}

.text-overlay {
  position: absolute;
  top: 30%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  color: white; 
  text-align: center; 
  padding: 20px; 
  border-radius: 8px; 
  
}

.our-team{
  margin-top: 8rem !important;
}

.container{
  color:white;
}

.experience{
  font-size: 50px;
  
}

.paragraph{
 font-size: 18px;
}

.firstpargraph{
  font-weight: bold;
  text-transform: uppercase;
}