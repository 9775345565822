
.header-image {
  width: 100%;
  height: 400px;
  position: relative; 
}

.text-overlay {
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  color: white;
  padding: 1rem; 
  text-transform: uppercase;
  font-size: 48px;
  font-weight: 100;
  line-height: 58.09px;
  text-align: center; 
  width: 80%; 
  box-sizing: border-box; 
}

.text-overlay p {
  margin-top: 18vh;
  font-weight: 500;
  line-height: 1.2; 
  text-align: center;
}

