/* Container */
.join-us-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    /* background-color: #f7f7f7; */
  }
  
  /* Left Container */
  .left-container {
    flex: 1;
    padding: 40px;
    /* background-color: #007BFF; */
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-bottom: 170px;
  }
  
  .left-container h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .left-container p {
    font-size: 1.5rem;
    line-height: 1.6;
    width:32rem;
  }
  
  /* Right Container */
  /* Right Container */
  .right-container {
    flex: 1;
    padding: 40px;
    /* background-color: white; */
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .right-container h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }
  
  .join-us-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: white;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .form-group textarea {
    height: 100px;
    resize: none;
  }
  
  .submit-btn {
    padding: 10px 15px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    background-color: #DD223E;
    color: white;
    cursor: pointer;
    margin-top: 10px;
    max-width: 85px;
  }
  

  